<script>
  import {
    required,
    helpers
  } from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import {get_projects, login} from "@/api/auth";
  import appConfig from "../../../app.config";
  import myLoader from "../../components/myLoader.vue";
 
  export default {
    setup() {
     return { v$: useVuelidate() };
    },
    page: {
      title: "Login",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        isLoader:false,
        user: "",
        password: "",
        architecture:null,
        architectures:[],
        submitted: false,
        authError: false,
        messageError:"",
        tryingToLogIn: false,
        isAuthError: false,
      };
    },
        validations: {
      user: {
        required: helpers.withMessage("Se requiere el usuario", required),
      },
     
      password: {
        required: helpers.withMessage("La clave es requerida", required),
      },
      architecture: {
        required: helpers.withMessage("La arquitectura es requerida", required),
      },
    },
    computed: {

    },
    components: {
      myLoader,
    },
    mounted() {
      this.get_dataProjects();
    },
    methods: {
      async get_dataProjects() {
        const result = await get_projects();
        this.architectures  = [];
        result.result.data.map((item) => {
          this.architectures.push({
            value: item.ID_PRY,
            name: item.NAME_PYR
          });
        });
      },  
     
      tryToLogIn() {
        this.submitted = true;
        // stop here if form is invalid
        this.v$.$touch();
        if (this.v$.$invalid) {
          return;
        }else{
          this.signinapi();
        }
      },
      async signinapi() {
        this.isLoader=true;
        const result = await login({
          'username' : this.user, 'password' :this.password, 'proyect' :this.architecture,
        })
        // console.log('signinapi',result);
        this.messageError='';
        if (result.state == false) {
          this.authError = true;
          if(typeof result.info.message==='object'){
            result.info.message.map((item) => {
              this.messageError=this.messageError+item+' \n';
            });
          }else{   
            this.messageError=result.info.message;
          }
          this.isLoader=false;
          return ;
        }else{
          this.isLoader=false;
          this.authError = false;
          this.$router.push({
            path: '/loginDB'
          });
        }
      }
      
    },
    
    
  }
  
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-none"></div>

      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8
            1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
           
          </div>
        </div>
        <!-- end row -->
        <div v-if="isLoader" style="position:absolute; top: 35%; 
          left:40%;
          z-index: 9999;  "  >
            <myLoader /> 
       </div>
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-5">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <div class="text-center mt-sm-1 mb-4 text-white-40">
                    <div>
                      <img src="@/assets/images/NSLogoLight.png" alt="" height="60" />
                    </div>
                    <p class="mt-3 fs-25 fw-medium">
                      Tecnología aplicada al transporte y la lógistica 
                    </p>
                  </div>
                </div>
                <div class="p-2 mt-4">
                  <b-alert v-model="authError" variant="danger" class="mt-3" dismissible>{{ messageError }}</b-alert>

                  <form @submit.prevent="tryToLogIn">
                    <div class="mb-3">
                      <label class="form-label">Usuario</label>
                      <input
                        type="text"
                        v-model="user"
                        class="form-control"
                        id="user"
                        :class= "[(submitted && Object.keys( v$.user.$errors).length === 0) ? 'is-valid': 
                        (submitted && Object.keys( v$.user.$errors).length != 0) ? 'is-invalid':'form-control' ] "
                        placeholder="usuario"
                      />
                      <div
                        v-for="(item, index) in v$.user.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                    </div>
                   
                    <div class="mb-3">
                      <div class="float-end">
                        <router-link to="/forgot-password" class="text-muted">Olvidó su clave?
                        </router-link>
                      </div>
                      <label class="form-label" for="password-input">Clave</label>
                      <div class="position-relative auth-pass-inputgroup mb-1">
                        <input type="password" v-model="password"
                         :class= "[(submitted && Object.keys( v$.password.$errors).length === 0) ? 'form-control pe-5 is-valid': 
                         (submitted && Object.keys( v$.password.$errors).length != 0) ? 'form-control pe-5 is-invalid':'form-control pe-5' ] "
                          placeholder="Entre su clave"
                          id="password-input"       />
                        <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button" id="password-addon">
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                      </div>
                      <div
                        v-for="(item, index) in v$.password.$errors"
                        :key="index"
                        class="text-danger" style="font-size: 13px"
                      >
                        <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                    </div>  
                     
                    
                    <div class="mb-6">
                      <label class="form-label">Arquitectura</label>
                      <select
                        :class= "[(submitted && Object.keys( v$.architecture.$errors).length === 0) ? 'form-select form-select-md is-valid': 
                        (submitted && Object.keys( v$.architecture.$errors).length != 0) ? 'form-select form-select-md is-invalid':'form-select form-select-md' ] "
                        aria-label=".form-select"
                        v-model="architecture" id="architecture"
                      >
                        <option v-for="item in architectures" 
                        :key="item.value" :value="item.value"
                        class="h-100"
                        >{{item.name}}
                        </option>
                      </select>
                      <div
                        v-for="(item, index) in v$.architecture.$errors"
                        :key="index"
                        class="text-danger" style="font-size: 13px"
                      >
                        <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                    </div>
                    <div class="mt-4">
                      <button class="btn btn-success w-100" type="submit" >
                        Ingresar
                      </button>
                    </div>

                  </form>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} NEXT STEP COLOMBIA 
                <i class="mdi mdi-heart text-danger"></i> Siempre Adelante
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>